// $primary: #a51616;
$primary: #339999;

/* MAIN COLOR */

$secondary: #003333;

/* SECONDARY COLOR */

$blk: #333;

/* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */

$wht: #fff;
$border-radius: 0px;

/* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */

$footerLinks: #fff;
h1,
h2,
h3 {
    font-family: 'Paytone One', sans-serif;
}

a,
p,
ol,
ul {
    font-family: 'Rancho', cursive;
    font-size: 1.25em;
}

a:focus {
    background: transparent;
}

.flash {
    display: none;
}

nav {
    z-index: 1000;
}

@media (min-width: 767px) and (max-width: 1023px) {
    .row {
        margin-left: 0px;
        margin-right: 0px;
    }
}

@media (min-width: 1024px) and (max-width: 1440px) {
    .row {
        margin-left: 0px;
        margin-right: 0px;
    }
}

.fade {
    background: rgba(0, 0, 0, 0.5) !important
}

.navbar-collapse {
    background: transparent;
    @media (max-width: 767px) {
        background: $wht;
    }
}

.navbar {
    background: transparent;
    border-bottom: 0px !important;
}

.navbar-default {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,e0e2e2+100 */
    background: rgb(255, 255, 255);
    /* Old browsers */
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(224, 226, 226, 1) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(224, 226, 226, 1) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(224, 226, 226, 1) 100%);
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    width: 100%;
}

.nav>li {
    height: 35px;
}

.navbar .navbar-nav {
    >li>a {
        text-align: center;
        display: flex;
        align-items: center;
        font-weight: bold;
        color: $primary;
        outline: none;
        @media (max-width: 767px) {
            height: 45px;
            display: inline-block;
            padding: 0px;
        }
    }
}

.nav .nav-divider {
    margin: 0px;
}

.navbar-right {
    margin-top: 8px;
    font-size: 1.5em;
    @media (max-width: 767px) {
        margin-top: 0px;
        margin-bottom: 0px;
        padding-bottom: 0px;
        .nav .nav-divider {
            margin: 0px;
        }
    }
}

.navbar-toggle {
    margin: 12px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
    @media (max-width: 388px) {
        margin-top: 14px;
    }
}

.navbar-default .navbar-nav>li>a:focus,
{
    color: $primary;
}

.navbar-default .navbar-nav>li>a:hover {
    color: $secondary;
}

.navbar-collapse.in {
    overflow: hidden;
}


/** LOGIN FORM **/

@mixin btn1 {
    text-align: center;
    margin: 0 auto;
    border: 1px solid $primary;
    border-radius: $border-radius;
    background: $primary;
    color: $wht;
    padding: 0.5em 2em;
    &:hover {
        background: lighten($primary, 10%);
        color: lighten($wht, 10%);
        box-shadow: 0px 0px 3px lighten($blk, 10%);
    }
}

@mixin btn2 {
    @include btn1;
    background: darken($wht, 5%);
    color: $primary;
    border: none;
    &:hover {
        background: $wht;
        color: $primary;
        border: none;
    }
}

.modal-dialog {
    max-width: 300px;
    text-align: center;
    margin: 6em auto;
    @media(max-width: 812px)and(orientation: landscape) {
        margin: 0 auto;
    }
    .close {
        display: none;
    }
    .modal-content {
        color: $primary;
        h2 {
            text-align: center;
        }
    }
    input {
        border: none;
        border-bottom: 1px solid darken($primary, 20%);
        text-align: center;
    }
    button {
        background: transparent;
        color: $wht;
        display: block;
        border: none;
        padding: 1em 2em;
        margin: 0 auto;
        &:hover {
            background: transparent;
            color: #fff;
            box-shadow: none;
            text-shadow: 0px 0px 3px $blk;
            border: none;
        }
    }
    input[type="submit"] {
        @include btn1;
        display: block;
        width: 75%;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

input#username {
    margin-bottom: 20px;
}


/** END LOGIN FORM **/

footer {
    padding: 50px 40px 20px;
    background: $primary;
    color: $footerLinks;
    a {
        color: $footerLinks;
        &:hover {
            color: lighten($footerLinks, 10%);
        }
    }
}

input#username,
input#password {
    width: 100%;
}

@media (max-width: 1024) {
    input#username,
    input#password {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}


/* ========= BUTTONS ============ */

.btn-download,
.btn-download:focus,
.btn-download:active,
.btn-download:visited {
    background-color: $primary;
    border-color: $wht;
    color: $wht;
    padding: 10px 20px;
    font-size: 19px;
    text-transform: uppercase;
    border-radius: 12px;
    transition: all 1s;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -o-transition: all 1s;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.btn-download:hover {
    background-color: $secondary;
    color: $wht;
}

.btn-download {
    margin-bottom: 15px !important;
    display: block;
    margin: 0 auto;
    max-width: 175px;
    margin-top: 50px;
    @media (max-width: 767px) {
        margin-top: 10px;
    }
    @media (max-width: 1024px) {
        margin-top: 10px;
    }
}


/* ========== HEADER ============== */

.header {
    background-image: url('../img/banner.jpg');
    background-repeat: no-repeat;
    background-position: 100% 0%;
    background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    -webkit-background-size: cover;
    margin-top: 55px;
    @media (max-width: 1199px) {
        background-position: 50% 20%;
    }
    @media (max-width: 1024px) {
        margin-top: 59px;
    }
}

.header-overlay {
    background-color: rgba(31, 31, 31, 0.5);
    padding: 225px 0px;
    @media (max-width: 767px) {
        padding: 0px;
    }
}

.header h1 {
    color: $wht;
    font-size: 5em;
    text-align: center;
    text-shadow: 2px 2px $secondary;
    padding-top: 0px;
}

.header p {
    color: $wht;
    font-size: 2em;
    letter-spacing: 2px;
    text-align: center;
    @media (max-width: 767px) {
        font-size: 1.75em;
        padding-bottom: 5px;
    }
    @media (min-width: 992px) and (max-width: 1024px) {
        margin-bottom: 50px!important;
        font-size: 1.75em;
    }
    @media (max-width: 1199px) {
        margin-bottom: 75px;
    }
}

.logo {
    width: 100%;
    max-width: 250px;
    margin-left: 10px;
    padding: 5px;
    @media (max-width: 1024px) {
        width: 100%;
        max-width: 217px;
        margin-left: 5px;
        padding: 5px;
    }
}

.header img {
    display: block;
    margin: 0 auto;
    width: 100%;
    @media (max-width: 1024px) {
        margin-top: 75px;
    }
}

.bottom-rip {
    background-image: url('../img/nav-bg-2.png');
    background-repeat: repeat-x;
    height: 130px;
    position: relative;
    border: 0px !important;
}


/* ========== MINIFORM AREA ============== */

.miniform {
    padding: 50px 0px;
    background-image: url('../img/miniform-bg.png');
    background-repeat: no-repeat;
    background-position: 60% 60%;
    background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    -webkit-background-size: cover;
    @media (max-width: 1024px) {
        padding: 35px 0px;
        text-align: center;
    }
}

.miniform h1 {
    font-size: 4em;
    color: $primary;
    text-shadow: 2px 2px $secondary;
    padding: 10px;
    @media (max-width: 767px) {
        font-size: 2.25em;
    }
}

.miniform h2 {
    font-size: 3em;
    color: $primary;
    text-shadow: 2px 2px $secondary;
    padding-bottom: 5px;
    @media (max-width: 767px) {
        font-size: 2em;
    }
    @media (max-width: 1023px) {
        padding-top: 25px;
    }
    @media (min-width: 1024px) and (max-width: 1199px) {
        padding-top: 0px;
        font-size: 2.5em;
        text-align: left;
    }
}

.miniform p {
    font-size: 1.65em;
    letter-spacing: 1px;
    line-height: 40px;
    padding-bottom: 5px;
    @media (min-width: 1024px) and (max-width: 1199px) {
        padding-top: 0px;
        font-size: 1.5em;
        line-height: 35px;
        text-align: left;
    }
}

.miniform img {
    width: 100%;
    max-width: 450px;
    display: block;
    margin: 0 auto;
    margin-bottom: 50px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.72), 0 6px 20px 0 rgba(0, 0, 0, 0.79);
}


/* ========== BENEFITS ============== */

.benefits {
    background-image: url('../img/lower-banner.jpg');
    background-repeat: no-repeat;
    text-align: center;
    background-position: 10% 80%;
    @media (max-width: 1024px) {
        background-position: 80% 60%;
    }
}

.benefits-overlay {
    background-color: rgba(31, 31, 31, 0.5);
    padding: 250px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    @media (max-width: 1024px) {
        padding: 0px;
        text-align: center;
    }
}

.benefits h1 {
    font-size: 3.5em;
    color: $wht;
    text-shadow: 2px 2px $primary;
    @media (max-width: 1024px) {
        font-size: 3em;
    }
    @media (max-width: 767px) {
        font-size: 2em;
    }
}

.benefits p {
    font-size: 1.65em;
    letter-spacing: 1px;
    line-height: 40px;
    padding: 10px;
    color: $wht;
}

.benefits img {
    width: 100%;
    max-width: 550px;
    display: block;
    margin: 0 auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    @media (max-width: 767px) {
        padding-top: 0px
    }
    @media (max-width: 1024px) {
        margin-top: 25px;
    }
    @media (max-width: 1199px) {
        margin-top: 60px
    }
}


/* ========== BENEFITS2 ============== */

.benefits2 {
    background-image: url('../img/lower-banner-2.jpg');
    background-repeat: no-repeat;
    text-align: center;
    background-position: 10% 80%;
    @media (max-width: 1024px) {
        background-position: 80% 60%;
    }
}

.benefits2-overlay {
    background-color: rgba(31, 31, 31, 0.5);
    padding: 250px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    @media (max-width: 1024px) {
        padding: 0px;
        text-align: center;
    }
}

.benefits2 h1 {
    font-size: 3.5em;
    color: $wht;
    text-shadow: 2px 2px $primary;
    @media (max-width: 1024px) {
        font-size: 3em;
    }
    @media (max-width: 767px) {
        font-size: 2em;
    }
}

.benefits2 p {
    font-size: 1.65em;
    letter-spacing: 1px;
    line-height: 40px;
    padding: 10px;
    color: $wht;
}

.benefits2 img {
    width: 100%;
    max-width: 550px;
    display: block;
    margin: 0 auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    @media (max-width: 767px) {
        padding-top: 0px
    }
    @media (max-width: 1024px) {
        margin-top: 25px;
    }
    @media (max-width: 1199px) {
        margin-top: 60px
    }
}


/* ========== FEATURES ============== */

.features {
    padding: 60px;
    @media (max-width: 767px) {
        padding: 0px;
    }
    @media (max-width: 1024px) {
        padding: 0px;
    }
    @media (min-width: 1024px) and (max-width: 1199px) {
        padding: 25px;
    }
}

.features h1 {
    font-size: 4em;
    color: $primary;
    text-shadow: 2px 2px $secondary;
    text-align: center;
    @media (max-width: 1024px) {
        font-size: 3em;
    }
}

.features h2 {
    font-size: 2em;
    color: $primary;
    text-shadow: 2px 2px $secondary;
    @media (max-width: 1024px) {
        text-align: center;
    }
}

.features p {
    font-size: 1.65em;
    letter-spacing: 1px;
    line-height: 35px;
    color: $primary;
    @media (max-width: 1024px) {
        text-align: center;
    }
    @media (min-width: 1024px) and (max-width: 1199px) {
        text-align: left;
        font-size: 1.5em;
        line-height: 30px;
    }
}

.features img {
    width: 100%;
    max-width: 550px;
    display: block;
    margin: 0 auto;
    margin-top: 25px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.72), 0 6px 20px 0 rgba(0, 0, 0, 0.79);
    @media (max-width: 1024px) {
        max-width: 250px;
        margin-bottom: 50px;
    }
    @media (min-width: 1024px) and (max-width: 1199px) {
        max-width: 450px;
        margin-bottom: 50px;
    }
}

.modal-open {
    overflow: auto;
    padding-right: 0px !important;
}

.terms,
.privacy {
    margin-top: 150px;
    @media (max-width: 767px) {
        margin-top: 100px;
    }
}